const allProjects = [

    {
      title: 'Timepeace',
      img: "https://raw.githubusercontent.com/PubXpo/timepeace/main/img/landing_screen.png",
      description: 'Engineered a robust web portal application to empower contractors in tracking billable projects and seamlessly generating client invoices.',
      githubUrl: 'https://github.com/PubXpo/timepeace',
      techStack: ['Java',  'Bootstrap', 'MySQL'],
    },
    {
      title: 'EasyCal',
      img: require('../img/easycal.png'),
      description: 'Developed an intuitive Android application to enhance accessibility for visually impaired students, enabling easy access to academic calendars and improved planning.',      githubUrl: 'https://github.com//PubXpo/easycal',
      techStack: ['Kotlin', 'XML', 'Android Studio'],
    },
    // {
    //   title: 'Widget',
    //   img: require('../img/easycal.png'),
    //   description: 'Engineered a hybrid mobile app for sale agents to process orders and deliveries using JavaScript, Node.Js, Cordova, and OpenStack.',
    //   githubUrl: 'https://github.com/widget',
    //   techStack: ['JavaScript', 'Node.Js', 'Cordova'],
    // },
    {
      title: 'Vessel vs Landings',
      img: require('../img/VessVLand.PNG'),
      description: 'Created a data-driven application to analyze the correlation between vessel lengths and fish imports, facilitating comprehensive data processing and visualization.',      githubUrl: 'https://github.com//PubXpo/VesVLand',
      techStack: ['Python', 'Docker', 'MongoDB'],
    },

  
];

export default allProjects;
