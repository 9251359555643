import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import realProfile from "../assets/img/profile.png";
import hoverProfile from "../assets/img/profilehover.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TypeAnimation } from 'react-type-animation';
import "./CSS/Home.css";

function Home() {
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [image, setImage] = useState(realProfile);

  function setAvatarReal() {
    setImage(hoverProfile);
  }

  function setAvatarAnimation() {
    setImage(realProfile);
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Welcome! | Lee</title>
        </Helmet>
      </HelmetProvider>
      <Container fluid className="home-wrapper">
        <div className="home-left animate__animated animate__fadeInLeft">
              <TypeAnimation 
                cursor={false}
                sequence={[
                  'Welcome to my \ndigital realm!',
                  () => setShowSubtitle(true)
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 120 }}
                wrapper="h2"
                repeat={0}
                className="display-linebreak"
              />
              {showSubtitle &&
              <>
              <div><h3 className="home-subtitle"></h3>
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    500,
                    'I craft code solutions with elegance.',
                    1000,
                    'I build experiences that inspire innovation.',
                    1000,
                    'I transform ideas into scalable marvels.',
                    1000,
                    'I drive efficiency through technology.',
                    1000,
                    'I unravel intricate challenges with finesse.',
                    500,
                  ]}
                  speed={50}
                  deletionSpeed={65}
                  wrapper="h3"
                  repeat={Infinity}
                  className="home-subtitle"
                />
               </div> 
              </>
              }
          <NavLink to="/contact" className="btn-connect text-center">
            Let's Connect
          </NavLink>
        </div>
        <div className="home-right animate__animated animate__fadeIn animate__slower">
          <img
            className="home-image"
            src={image}
            onMouseOver={setAvatarReal}
            onMouseOut={setAvatarAnimation}
            alt="Avatar"
          />
        </div>
      </Container>
    </>
  );
}

export default Home;
