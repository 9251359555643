import { Container } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./CSS/Skills.css";

function Skills() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Skills | Lee</title>
        </Helmet>
      </HelmetProvider>
      <Container fluid className="skills-wrapper">
        <div className="skills-left animate__animated animate__zoomIn">
          <h3>Skills</h3>
          <h4>
            ───&nbsp;&nbsp;Page <strong>03</strong>
          </h4>
        </div>
        <div className="skills-right">
  
          <div className="row my-4 animate__animated animate__flipInX animate_slow">
            <div className="col">
              <i className="devicon-python-plain-wordmark colored" title="Python"></i>
              <i className="devicon-java-plain-wordmark colored" title="Java"></i>
              <i className="devicon-kotlin-plain colored" title="Kotlin"></i>
              <i className="devicon-cplusplus-plain colored" title="C++"></i>
            </div>
          </div>
          <div className="row my-4 animate__animated animate__flipInX animate_slow">
            <div className="col">
              <i className="devicon-html5-plain-wordmark colored" title="HTML / XHTML"></i>
              <i className="devicon-css3-plain-wordmark colored" title="CSS"></i>
              <i className="devicon-javascript-plain colored" title="JavaScript"></i>
              <i className="devicon-typescript-plain colored" title="TypeScript"></i>
            </div>
          </div>
          <div className="row my-4 animate__animated animate__flipInX animate_slow">
            <div className="col">
              <i className="devicon-react-original-wordmark colored" title="ReactJS"></i>
              <i className="devicon-nodejs-plain colored" title="Node.js"></i>
              <i className="devicon-spring-plain-wordmark colored" title="Spring Boot"></i>
              <i className="devicon-bootstrap-plain-wordmark colored" title="Bootstrap"></i>
            </div>
          </div>
          <div className="row my-4 animate__animated animate__flipInX animate_slow">
            <div className="col">
              <i className="devicon-docker-plain-wordmark colored" title="Docker"></i>
              <i className="devicon-bash-plain colored" title="Bash"></i>
              <i className="devicon-mongodb-plain-wordmark colored" title="MongoDB"></i>
              <i className="devicon-mysql-plain-wordmark colored" title="MySQL"></i>
            </div>
          </div>
          <div className="row my-4 animate__animated animate__flipInX animate_slow">
            <div className="col">
              <i className="devicon-sass-original colored" title="Sass"></i>
              <i class="devicon-androidstudio-plain colored" title="Android Studio"></i>
              <i className="devicon-jupyter-plain colored" title="Jupyter"></i>
              <i className="devicon-git-plain colored" title="Git"></i>

            </div>
          </div>
         
        </div>
      </Container>
    </>
  );
}

export default Skills;
